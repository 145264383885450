import React, { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { setDismissableAlert } from '../../../utilities/alert/setDismissableAlert';

import '../../../styles/documentDrawerJobs.css';
import { VUES } from '../../../constants/constantStrings';
import formatDate, { getDateTimeString } from '../../../utilities/format/formatDate';
import UTCDatePicker from '../../../components/Inputs/UTCDatePicker/UTCDatePicker';
import dateToUTCMidnight from '../../../utilities/date/dateToUTCMidnight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import ReactJson from 'react-json-view';

export class DocumentDrawerJobs extends Component {
    state = {
        jobs: [],
        selectedJobs: [],
        loadingJobs: false,
        jobLimit: 0,
    };

    async componentDidMount() {
        await this.loadJobs();
    }

    async componentDidUpdate(prevProps) {
        const p = this.props;
        const doc = {
            ...p.document,
            ...p.docDiff,
        };

        if (doc._id !== prevProps?.document?._id) {
            await this.setState({ jobs: [], selectedJobs: [], jobLimit: 0 }); // Reset the state when the document changes.
            await this.loadJobs();
        }
    }

    async loadJobs() {
        const p = this.props;
        const doc = {
            ...p.document,
            ...p.docDiff,
        };

        if (!doc._id) {
            return;
        }

        if (this.state.jobLimit === 0) {
            return;
        }
        this.setState({ loadingJobs: true });
        const jobs = await this.getJobsByDocumentId(doc._id, this.state.jobLimit);
        console.log('jobs', jobs);
        this.setState({ jobs, loadingJobs: false });
    }

    async getJobsByDocumentId(documentId, limit = 3) {
        const jobs = await api.get('/documents/' + documentId + '/jobs?count=' + limit + '&sort=-created_at');
        return jobs;
    }

    handleJobSelection = (selectedOptions) => {
        this.setState({ selectedJobs: selectedOptions });
    };

    handleSendJobs = async () => {
        const document_id = this.props.document._id;
        const selectedJobs = this.state.selectedJobs;
        const jobIds = selectedJobs.map((job) => job.value);
        this.props.loading(320, 'docDrawerJobs');
        const response = await api.post('/documents/' + document_id + '/jobs', { job_list: jobIds });
        if (response) {
            await this.loadJobs();
        } else {
            setDismissableAlert(this.props.setAlert, 'Error sending jobs.', true);
        }
        this.props.loaded('docDrawerJobs');
    };

    loadMoreJobs = () => {
        this.setState({ jobLimit: 20 }, () => {
            this.loadJobs();
        });
    };

    loadSomeJobs = () => {
        this.setState({ jobLimit: 3 }, () => {
            this.loadJobs();
        });
    };

    jobOptions = [
        { label: 'Pre-Process', value: 'preprocess' },
        { label: 'Sort', value: 'sort' },
        { label: 'Extract', value: 'extract' },
    ];

    jobStatusOptions = [
        { label: 'Success', value: 'success' },
        { label: 'Failure', value: 'failure' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Pending', value: 'pending' },
    ];

    render() {
        const s = this.state;
        const p = this.props;

        if (!p.document) {
            return <div className="docJobs-component"></div>;
        }

        return (
            <div className="docJobs-component">
                <div className="docDrawer-title">Document Jobs</div>

                <div className="docJobs-actions">
                    <div className="docJobs-actions-select">
                        <Select isMulti options={this.jobOptions} onChange={this.handleJobSelection} />
                    </div>
                    <div className="docJobs-actions-send">
                        <button onClick={this.handleSendJobs}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>

                {s.loadingJobs && <div className="docJobs-loading">Loading...</div>}
                {!s.loadingJobs && (
                    <ul className="docJobs-list">
                        {s.jobs.map((job) => (
                            <li key={job._id} className="docJobs-item">
                                <div className="docJobs-item-header">
                                    <span className="docJobs-item-type">{this.jobOptions.find((i) => job.job_type === i.value)?.label ?? job.job_type}</span>
                                    <span className="docJobs-item-status">{this.jobStatusOptions.find((o) => o.value === job.exit_status)?.label ?? job.exit_status}</span>
                                </div>
                                <div className="docJobs-item-info">
                                    <div>Job ID: {job._id}</div>
                                    <div>Created: {getDateTimeString(job.created_at)}</div>
                                    <div>Started: {getDateTimeString(job.started_at)}</div>
                                    <div>Completed: {getDateTimeString(job.completed_at)}</div>
                                    <div>
                                        Duration:{' '}
                                        {job.completed_at && job.started_at
                                            ? `${(new Date(Date.parse(job.completed_at)) - new Date(Date.parse(job.started_at))) / 1000} seconds`
                                            : 'N/A'}
                                    </div>
                                    <div>Status: {job.exit_status}</div>
                                </div>
                                {job?.completion_result && (
                                    <div className="docJobs-item-result">
                                        <ReactJson src={job.completion_result} name={false} displayDataTypes={false} theme={'ashes'} collapsed={true} />
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                )}

                <div className="docJobs-more">
                    {s.jobLimit === 0 && s?.jobs?.length === 0 && !s.loadingJobs ? (
                        <a href="#" onClick={this.loadSomeJobs}>
                            Load Past 3 Jobs
                        </a>
                    ) : s.jobLimit === 3 && s?.jobs?.length === 3 && !s.loadingJobs ? (
                        <a href="#" onClick={this.loadMoreJobs}>
                            More
                        </a>
                    ) : null}
                    {!s.loadingJobs && s.jobs?.length ? (
                        <a
                            href="#"
                            onClick={() => {
                                this.setState({ jobLimit: 0, jobs: [] });
                            }}
                        >
                            Close
                        </a>
                    ) : null}
                    {!s.loadingJobs && s.jobs?.length > 0 && (
                        <a
                            href="#"
                            onClick={() => {
                                this.loadJobs();
                            }}
                        >
                            Reload
                        </a>
                    )}
                </div>
            </div>
        );
    }
}
