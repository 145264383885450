// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-NotificationSettingControls.css';
import _ from 'lodash';
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Multitoggle from '../Toggles/Multitoggle';

const isMobile = () => window.innerWidth <= 768;

// in order to order the list
const NOTIFICATION_TYPES = [
    // 'Master Enabled',
    'Capital Call',
    'Distribution',
    'Tax Document',
    // 'Connection Active',
    // 'Connection Error',
];

class NotificationSettingControls extends Component {
    state = {
        user: null,
        notificationSettings: null,
        showNotifTooltip: false,
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(prev.user, this.props.user)) {
            this.loadData();
        }
    };

    loadData = () => {
        this.setState({
            user: this.props.user,
            notificationSettings: this.props.user?.notificationSettings,
        });
    };

    render = () => {
        const settings = this.state.user?.notificationSettings ?? {};
        if (!settings) return null;
        return (
            <>
                <div className="investment-accounts" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1 style={{ margin: '0px' }}>Notification Settings</h1>
                        <div style={{ width: '20px', height: '20px' }}>
                            <div
                                className="show-tooltip"
                                style={{ marginLeft: '20px' }}
                                onClick={isMobile() ? () => this.setState({ showNotifTooltip: !this.state.showNotifTooltip }) : null}
                                onMouseEnter={!isMobile() ? () => this.setState({ showNotifTooltip: true }) : null}
                                onMouseLeave={!isMobile() ? () => this.setState({ showNotifTooltip: false }) : null}
                            >
                                ?
                            </div>
                            {this.state.showNotifTooltip && (
                                <div className="popper">
                                    Turn ON notifications below to receive an email for an upcoming capital call, distribution, or when we have received a current tax document.
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="accounts-list"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                        }}
                    >
                        {NOTIFICATION_TYPES.map((key, keyIdx) => {
                            const enabled = settings[key];
                            return (
                                <div style={{ display: 'flex', height: '40px', alignItems: 'center' }} key={`notif${keyIdx}`}>
                                    <div style={{ width: '200px', fontSize: '18px' }}>{settings[key]?.alias ?? key}</div>
                                    <Multitoggle
                                        keyStr={'notifSettingToggle'}
                                        options={['OFF', 'ON']}
                                        selection={enabled ? 'ON' : 'OFF'}
                                        zIndex={2000}
                                        onSelect={async () => {
                                            const stateSettings = this.state.notificationSettings;
                                            const newEnabled = !enabled;
                                            stateSettings[key] = newEnabled;
                                            this.setState({ notificationSettings: stateSettings });
                                            const res = await api.patch(`/users/${this.state.user._id}/notificationSettings`, {
                                                key,
                                                enabled: newEnabled,
                                            });
                                            if (!res) {
                                                stateSettings[key] = enabled;
                                                this.setState({ notificationSettings: stateSettings });
                                                setDismissableAlert(this.props.setAlert, 'Error changing notification setting', true);
                                            }
                                        }}
                                        containerStyle={{
                                            width: '130px',
                                            height: '24px',
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    };
}

export default withRouter(NotificationSettingControls);
