// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

export default {
    emptyText: 'See top performing and underachieving investments.',
    textStyle: {
        fontSize: '16px',
    },
    props: {
        investments: [
            {
                name: 'Private Equity Fund',
                performance: {
                    itd: {
                        irr: 13.4,
                    },
                },
            },
            {
                name: 'Bitcoin',
                performance: {
                    itd: {
                        irr: 0.3,
                    },
                },
            },
            {
                name: 'Real-Estate Fund',
                performance: {
                    itd: {
                        irr: 0.05,
                    },
                },
            },
            {
                name: 'Real-Estate Fund II',
                performance: {
                    itd: {
                        irr: 0,
                    },
                },
            },
            {
                name: 'Real-Estate Fund III',
                performance: {
                    itd: {
                        irr: 0,
                    },
                },
            },
            {
                name: 'Real-Estate Fund IV',
                performance: {
                    itd: {
                        irr: 0,
                    },
                },
            },
            {
                name: 'Private Equity Fund IV',
                performance: {
                    itd: {
                        irr: -0.3,
                    },
                },
            },
            {
                name: 'Real-Estate Fund III',
                performance: {
                    itd: {
                        irr: -0.3,
                    },
                },
            },
            {
                name: 'Hedge Fund',
                performance: {
                    itd: {
                        irr: -0.3,
                    },
                },
            },
        ],
    },
};
