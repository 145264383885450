// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import api from '../../api';
import '../../styles/widget.css';
import '../../styles/widget-CapitalCalls.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import EmptyOverlay from '../EmptyStates/EmptyOverlay';
import emptyStateData from '../EmptyStates/emptyStateFakeData';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import { getNetType } from '../../utilities/netTransactionUtils';
import { calculateNetAmountFromTransaction } from '../../utilities/calculate/calculateNetAmount';
import api2 from '../../api2';

class CapitalCallsWidget extends Component {
    state = {
        callsDue: [],
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prevProps) => {
        if (!_.isEqual(prevProps.investments, this.props.investments)) {
            this.loadData();
        }
    };

    loadData = async () => {
        const p = this.props;
        const s = this.state;
        const callsDue = (
            await api2.client.TransactionApi.listTransactions({
                types: ['capital_call'],
                open: true,
                sort_field: 'date',
                sort_direction: 'desc',
                populate_investment: true,
                investments: p.investments.map((i) => i._id),
            })
        ).data.transactions;
        this.setState({ callsDue });
    };

    displayDate = (dateStr) => {
        const date = dateToUTCMidnight(dateStr);
        const nowUtc = dateToUTCMidnight();
        if (date.getTime() < nowUtc.getTime()) return 'Past Due';

        if (date.getTime() < nowUtc.getTime() + 24 * 60 * 60 * 1000) return 'Today';

        if (date.getTime() < nowUtc.getTime() + 48 * 60 * 60 * 1000) return 'Tomorrow';

        return null;
    };

    updateMetStatus = async (id, met) => {
        let callsDue = [...this.state.callsDue];
        const foundIdx = callsDue.findIndex((call) => call._id === id);
        callsDue[foundIdx].open = !met;
        this.props.updateCallsDue(callsDue);

        try {
            await api2.client.TransactionApi.updateTransaction({
                transaction_id: id,
                UpdateTransactionRequest: { open: !met },
            });
        } catch (err) {
            console.error('Error updating met status of capital call.');
        }
    };

    renderCallNotice = (call, idx) => {
        let investment = call.investment;

        if (this.props.showEmptyState) {
            // get investments from the fake data if props.showEmptyState is true
            const investments = emptyStateData.CapitalCallsWidget.props.investments;
            investment = investments?.find((i) => i._id === call.investment);
        }

        if (!investment) return null;

        const displayDate = this.displayDate(call.date);

        let amount = call.amount;
        if (amount < 0) return null;

        let extraMessage = '';
        if (call.joint_transaction) {
            const netType = getNetType(call.joint_transaction);
            const netAmount = calculateNetAmountFromTransaction(call.joint_transaction);
            extraMessage += `(from ${netType} with total of ${formatCurrency(netAmount, investment?.currency)})`;
        }

        return (
            <React.Fragment key={'noticeRow' + idx}>
                <div className="callNotices_row">
                    <div className="callNotices_row_metContainer">
                        <div className={'callNotices_row_metCircle'} onClick={() => this.updateMetStatus(call._id, call.open)}>
                            {!call.open && (
                                <div className="callNotices_row_metCircleCheckContainer">
                                    <div className="callNotices_row_metCircleCheck" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="callNotices_row_info">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {`${formatCurrency(amount, investment?.currency)} due` ?? '$???'}
                            <span
                                style={{
                                    fontSize: '12px',
                                    marginLeft: '10px',
                                }}
                            >
                                {extraMessage}
                            </span>
                        </div>
                        <div onClick={() => this.props.viewInvestment(investment._id)}>{investment.name}</div>
                    </div>
                    <div
                        className={
                            'callNotices_row_dueDate' +
                            (displayDate ? ' callNotices_row_dueDateHighlight' : '') +
                            (displayDate === 'Past Due' ? ' callNotices_row_dueDatePast' : '')
                        }
                    >
                        {displayDate ?? moment(call.date).format('MMM Do')}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render = () => {
        // get callsDue from the fake data if props.showEmptyState is true
        const callsDue = this.props.showEmptyState ? emptyStateData.CapitalCallsWidget.props.callsDue : this.state.callsDue;

        if (!callsDue) return <></>;

        return (
            <EmptyOverlay isEmpty={this.props.showEmptyState} emptyText={emptyStateData.CapitalCallsWidget.emptyText} textStyle={emptyStateData.CapitalCallsWidget.textStyle}>
                <div className="widget callNotices_component">
                    <div className="callNotices_header">
                        {callsDue.length > 0 && (
                            <div className="callNotices_headerText">
                                You have {callsDue.length} capital call notice{callsDue.length > 1 ? 's' : ''}
                            </div>
                        )}
                        {callsDue.length === 0 && <div className="callNotices_headerText">You have no capital calls due.</div>}
                        {callsDue.length > 0 && (
                            <div className="callNotices_headerSeeAll">
                                <span onClick={() => this.props.history.push(`/accounts/${this.props.match.params.account}/calls`)}>see all</span>
                            </div>
                        )}
                    </div>

                    {callsDue.length !== 0 && (
                        <div style={{ marginLeft: '0px' }} className="callNotices_met">
                            Unfunded
                        </div>
                    )}

                    {callsDue.map(this.renderCallNotice)}
                </div>
            </EmptyOverlay>
        );
    };
}

export default withRouter(CapitalCallsWidget);
