// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import _ from 'lodash';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EM_DASH } from '../../constants/constantStrings';
import '../../styles/widget-AllocationTable.css';
import '../../styles/widget.css';
import { calcTotalValue } from '../../utilities/calculate/financeCalculations';
import formatCurrency from '../../utilities/format/formatCurrency';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';

class AllocationTable extends Component {
    state = {
        types: [],
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(this.props.investments, prev.investments)) this.loadData();
    };

    loadData = () => {
        const investments = this.props.investments;
        if (!investments?.length) {
            this.setState({ types: [] });
            return;
        }

        this.props.loading(320, 'allocationTable');

        const investmentTypes = new Set(investments.map((i) => i.type));

        const totalCurrentValue = investments.reduce((acc, inv) => acc + inv.valuation, 0);
        const typesInvested = {};

        for (const type of Array.from(investmentTypes)) {
            let typeInvestments = investments.filter((i) => i.type === type);

            // get total value of investments for this type
            let typeInvested = calcTotalValue(typeInvestments);
            let displayInvested = formatCurrency(typeInvested);

            // get Current Value (unrealized) of investments for this type
            let typeCurrentValue = typeInvestments.reduce((acc, inv) => acc + inv.valuation, 0);
            let displayCurrentValue = formatCurrency(typeCurrentValue);

            // get percentage of total current value for this type
            let typeCurrentValuePercentage = typeCurrentValue / totalCurrentValue;
            let displayCurrentValuePercentage = isFiniteNonzero(typeCurrentValuePercentage) ? (100 * typeCurrentValuePercentage).toFixed(1) + '%' : EM_DASH;

            typesInvested[type] = {
                invested: displayInvested,
                current_value: displayCurrentValue,
                current_value_percent: displayCurrentValuePercentage,
            };
        }

        // if (!this.props.advisorVue)
        //     if (!investments || !investments.length) return;

        const typeDataArray = [];
        // Now compile allocation %s
        for (const type in typesInvested) {
            typeDataArray.push({
                type: type,
                invested: typesInvested[type].invested,
                current_value: typesInvested[type].current_value,
                current_value_percent: typesInvested[type].current_value_percent,
            });
        }

        this.setState({ types: typeDataArray });

        this.props.loaded('allocationTable');
    };

    render = () => {
        return (
            <div className="widget allocTblComponent">
                <h1 className="widget-header">Current Value by Type</h1>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Invested</th>
                            <th>Current Value</th>
                            <th>% of Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.types.map((d, idx) => {
                            return (
                                <tr key={`tr_${idx}`}>
                                    <td>{d.type}</td>
                                    <td>{d.invested}</td>
                                    <td>{d.current_value}</td>
                                    <td>{d.current_value_percent}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };
}

export default withRouter(AllocationTable);
