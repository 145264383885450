import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api2 from '../../../../api2';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';
import { UserAccess } from '../../../../types/User';
import apiClient from '../../../../apiClient';
import api from '../../../../api';

export interface UserUploadHandlerConfig extends BulkUploadHandlerConfig {
    access?: string;
}

const USER_ACCESS_MAP: { [key in UserAccess]: string } = Object.values(UserAccess).reduce(
    (acc, val) => {
        const value = capitalizeFirstEveryWord(val.replace('_', ' '));
        acc[val] = value;
        return acc;
    },
    {} as { [key in UserAccess]: string }
);

export interface UserUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    name?: string;
    email?: string;
    password?: string;
    access?: string;
}

const accessField = sharedFieldEnum(Object.values(USER_ACCESS_MAP).map((val) => capitalizeFirstEveryWord(val)));

class UserUploadHandler extends BulkUploadHandler<UserUploadHandlerCreateType, UserUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'user' or 'valuation'
    type = BulkUploadType.user;

    // The base columns that are required for the bulk upload
    base_columns = {
        name: {
            ...sharedFields.string,
            displayName: 'Name',
            fieldName: 'name',
            required: false,
        },
        email: {
            ...sharedFields.string,
            displayName: 'Email',
            fieldName: 'email',
            required: true,
        },
        password: {
            ...sharedFields.string,
            displayName: 'Password',
            fieldName: 'password',
            isValid: (val: string) => !!val.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{10,}$/g), // from api validation
            invalidMessage: 'password must be at least 10 characters long and contain at least one letter, one number, and one special character',
            required: true,
        },
        access: {
            ...accessField,
            displayName: 'Access',
            fieldName: 'access',
            isValid: (val: string) => {
                return Object.values(UserAccess)
                    .map((access) => access.toLowerCase())
                    .includes(val.toLowerCase());
            },
            format: (val: string) => {
                return Object.values(UserAccess).find((access) => access.toLowerCase() === val.toLowerCase()) || '';
            },
            required: true,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof UserUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof UserUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof UserUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);

        // set up special conditions based on the config
        if (this.config.access) {
            // remove access column if access is provided
            delete sortedColumns.access;
        } else if (sortedColumns.access) {
            // set access as required if not provided
            sortedColumns.access.required = true;
        }

        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof UserUploadHandlerCreateType]: string }, columnOrder?: (keyof UserUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof UserUploadHandlerCreateType)[]): UserUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        return {
            name: parsedValues.name,
            email: parsedValues.email,
            password: parsedValues.password,

            // Only include the access if it's provided and valid.
            ...(parsedValues.access
                ? {
                      access:
                          expectedColumns.access && expectedColumns.access.isValid(parsedValues.access)
                              ? USER_ACCESS_MAP[parsedValues.access.toLowerCase() as keyof typeof USER_ACCESS_MAP]
                              : '',
                  }
                : {}),
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (textFileResult: FileTextResult, columnOrder?: (keyof UserUploadHandlerCreateType)[]): Promise<FileParseResult<UserUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<UserUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<UserUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof UserUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        // require investment from config or in data
        if (!this.config.access && !data.access) {
            return { success: false, message: 'Access level is required' };
        }

        let access = data.access.toLowerCase();

        // convert access to the correct format from the display name
        if (
            Object.values(USER_ACCESS_MAP)
                .map((val) => val.toLowerCase())
                .includes(access)
        ) {
            access = Object.keys(USER_ACCESS_MAP).find((key) => USER_ACCESS_MAP[key as UserAccess].toLowerCase() === access) || '';
        }

        // check if access is valid
        if (
            !Object.keys(USER_ACCESS_MAP)
                .map((key) => key)
                .includes(access)
        ) {
            return { success: false, message: 'Invalid access level' };
        }

        try {
            // Attempt to create a single user from the current object.
            const createRes = await api.post(
                '/users',
                {
                    name: data.name,
                    email: data.email,
                    password: data.password,
                    access,
                },
                {},
                true
            );

            if (createRes.success && createRes.user?._id) {
                return {
                    success: true,
                    message: 'User created successfully',
                    id: createRes.user?._id,
                };
            } else {
                return {
                    success: false,
                    message: `Error creating user.`,
                };
            }
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating user:', err);
            return {
                success: false,
                message: `Error creating user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            const deleteRes = await api.delete(`/users/${id}`, {}, true);
            return {
                success: true,
                message: 'User deleted successfully',
            };
        } catch (err) {
            console.log('error deleting user:', err);
            return {
                success: false,
                message: `Error deleting user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the UserUploadHandler
export const getUserUploadHandler: (config: UserUploadHandlerConfig) => UserUploadHandler = (config: UserUploadHandlerConfig) => new UserUploadHandler(config);

export default UserUploadHandler;
