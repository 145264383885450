// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../../styles/widget.css';

export default class WidgetColumn extends Component {
    render = () => (
        <div className={'widget-column' + (this.props.className ? ` ${this.props.className}` : '')}>
            {this.props.children}
            <div style={{ clear: 'both', height: 40 }} />
        </div>
    );
}
