import saveAs from 'file-saver';

const saveCsv = (data: string | string[][], filename: string) => {
    let csvContent: string;
    if (typeof data === 'string') {
        csvContent = data;
    } else {
        csvContent = data.map((row) => row.map((cell) => `"${cell.replace(/"/g, '""')}"`).join(',')).join('\n');
    }
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
};

export default saveCsv;
