// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import EmptyOverlay from '../../EmptyStates/EmptyOverlay';
import emptyStateData from '../../EmptyStates/emptyStateFakeData';

import '../../../styles/widget.css';
import '../../../styles/widget-AccountPerformance.css';
import routes from '../../../routes';

class AccountPerformance extends Component {
    renderAmount = (amount, row) => {
        // console.log(amount)
        amount = amount ? amount * 100 : 0;
        let className = 'performance-summary-zero-amount';
        if (amount < 0) {
            className = 'performance-summary-negative-amount';
        } else if (amount) {
            className = 'performance-summary-positive-amount';
        }
        //Determine how many digits to show
        let digits = 0;
        if (amount > -100 && amount < 100) {
            digits = 1;
        }

        let displayVal = amount.toLocaleString('en-US', { maximumFractionDigits: digits, minimumFractionDigits: digits });
        //Add sign/unit
        if (amount > 0) {
            displayVal = '+ ' + displayVal;
        } else if (amount < 0) {
            displayVal = '- ' + displayVal.slice(1);
        }
        displayVal = displayVal + '%';
        return (
            <td className={className + ' performance-summary-amount'} key={'accountperformance_' + row + '_stat'}>
                {displayVal}
            </td>
        );
    };

    calculateGainLoss = (gain, contributions) => {
        // gain = !isNaN(gain) ? gain : 0;
        // contributions = !isNaN(contributions) ? contributions : 0;
        // gain = isFinite(gain) ? gain : 0;
        // contributions = isFinite(contributions) ? contributions : 0;
        // let amount = Math.round((gain / contributions) * 100);
        // amount = !isNaN(amount) ? amount : 0;
        // amount = isFinite(amount) ? amount : 0;
        return gain;
    };

    renderInvestment = (inv, rowIdx) => {
        if (!inv) return null;
        const invDetailPath = routes.investor.investmentDetails(this.props?.account?._id, inv?._id);
        return (
            <tr key={'accountPerformanceRow' + rowIdx}>
                <td
                    key={'accountPerformanceRow' + rowIdx + '_name'}
                    // onClick={() => this.props.history.push(invDetailPath)}
                    style={{ cursor: 'pointer' }}
                    className="performance-summary-name"
                    title={inv?.name}
                >
                    <a href={invDetailPath}>{inv?.name ?? ''}</a>
                </td>
                {this.renderAmount(inv?.performance?.itd?.irr)}
            </tr>
        );
    };

    renderSplitTable = (investments) => {
        return (
            <tbody>
                <tr>
                    <th>Top Performers</th>
                </tr>
                {investments.slice(0, 3).map(this.renderInvestment)}
                <tr>
                    <th>Under Achievers</th>
                </tr>
                {investments.reverse().slice(0, 3).reverse().map(this.renderInvestment)}
            </tbody>
        );
    };

    renderSolidTable = (investments) => {
        return <tbody>{investments.length && investments.map(this.renderInvestment)}</tbody>;
    };

    render = () => {
        // get investments from the fake data if props.showEmptyState is true
        let investments = this.props.showEmptyState ? emptyStateData.AccountPerformanceWidget.props.investments : this.props.investments;

        if (investments.length)
            investments =
                investments
                    ?.slice()
                    .filter((i) => i?.performance?.itd?.irr || i?.performance?.itd?.irr === 0)
                    .sort((i1, i2) => {
                        return i2?.performance?.itd?.irr === i1?.performance?.itd?.irr ? 0 : i2?.performance?.itd?.irr > i1?.performance?.itd?.irr ? 1 : -1; //descending
                    }) ?? [];
        // console.log(investments.map(i => i?.performance?.itd?.irr));

        let splitTable = false;

        if (investments.length > 8) {
            splitTable = true;
        }

        return (
            <EmptyOverlay
                isEmpty={this.props.showEmptyState}
                emptyText={emptyStateData.AccountPerformanceWidget.emptyText}
                textStyle={emptyStateData.AccountPerformanceWidget.textStyle}
            >
                <div className="widget">
                    <div className="account-performance-header">
                        <h1 className="account-performance-header">Performance</h1>
                        <div>
                            <Link className="performance-see-all" to={`/accounts/${this.props?.account?._id ?? 'all'}/details`}>
                                see all investments
                            </Link>
                        </div>
                    </div>

                    <table className="account-performance-summary-table">{splitTable ? this.renderSplitTable(investments) : this.renderSolidTable(investments)}</table>
                </div>
            </EmptyOverlay>
        );
    };
}

export default withRouter(AccountPerformance);
