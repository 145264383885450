import React from 'react';
import Dropzone from 'react-dropzone';

import '../styles/dragDropComponent.css';

const stopEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

const FILE_STATUSES = {
    unset: 'unset',
    error: 'error',
    processing: 'processing',
    success: 'success',
};

const PROCESSING_TIME = 0.7; // ms
const PROCESSED_DELAY = 0.3; // ms

/*

props {
    onDrop: callback when file dropped
    fileStatus: enum - [success, error, processing, unset]
    file
    clearFile
    fileStatuses
} 

*/

// convert a file to a blob
// the file sent to the onDrop callback can't be used directly
// it must be converted to a blob
export const fileToBlob = async (_file) => {
    const arrayBuffer = await _file.arrayBuffer();
    if (_file.type === 'text/csv') {
        const text = new TextDecoder().decode(arrayBuffer);
        return new Blob([text], { type: _file.type });
    }
    const uint8Array = new Uint8Array(arrayBuffer);
    return new Blob([uint8Array], { type: _file.type });
};

// props: {onDrop, fileStatus, file, clearFile, fileStatuses}
export const DragDropComponent = (props) => {
    const [dragging, setDragging] = React.useState(false);
    const [fileStatus, setFileStatus] = React.useState(FILE_STATUSES.unset);

    React.useEffect(() => {
        if (!props.file) {
            setFileStatus(FILE_STATUSES.unset);
        }
    }, [props.file]);

    const setProcessingThenStatus = (status) => {
        if (!Object.values(FILE_STATUSES).includes(status)) return;
        setFileStatus(FILE_STATUSES.processing);
        setTimeout(
            () => {
                setFileStatus(status);
            },
            (PROCESSING_TIME + PROCESSED_DELAY) * 1000
        );
    };

    const onDrop = (files) => {
        if (files.length < 1) return;
        const file = files[0];
        if (props.allowedFileTypes && !props.allowedFileTypes.includes(file.type)) {
            //exception for csv files
            if (props.allowedFileTypes.includes('text/csv')) {
                if (file.name.includes('.csv')) {
                    props.onDrop(file);
                    setProcessingThenStatus(FILE_STATUSES.success);
                    return;
                }
            }
            props.clearFile();
            setProcessingThenStatus(FILE_STATUSES.error);
        } else {
            props.onDrop(file);
            setProcessingThenStatus(FILE_STATUSES.success);
        }
    };

    const getAllowedTypesString = () => {
        let str = '';
        if (props.allowedFileTypes) {
            const listStr = props.allowedFileTypes.map((t) => (t.includes('/') ? t.split('/')[1] : t));
            str = `Supported formats: ${listStr.join(', ')}`;
        }
        return str;
    };

    const getStyle = () => {
        return {
            '--processing-time': PROCESSING_TIME + 's',
            ...props.containerStyle,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        };
    };

    let dropRef = React.createRef();

    const renderBrowseFiles = () => {
        return (
            <div
                className="a"
                onClick={async () => {
                    await dropRef.current.open();
                }}
            >
                Browse Files
            </div>
        );
    };

    const renderSupportedFileTypes = () => {
        if (!props.allowedFileTypes) return null;
        return <div className="addEditDocument-dragDrop-filetypes">{getAllowedTypesString()}</div>;
    };

    const filename = props.file?.name ?? 'Unknown filename';

    return (
        <div
            style={{
                width: '100%',
                height: 'calc(100vh - 80px)',
            }}
        >
            <Dropzone onDrop={onDrop} ref={dropRef}>
                {({ getRootProps, getInputProps }) => (
                    <>
                        <input {...getInputProps()} />
                        <div
                            style={getStyle()}
                            {...getRootProps({ className: 'dragDropComponent' + (dragging ? ' dragDropComponent-dragging' : '') })}
                            onDragOver={() => setDragging(true)}
                            onDragLeave={() => setDragging(false)}
                        >
                            {/* Unset */}
                            {fileStatus === FILE_STATUSES.unset && (
                                <div className="dragDropComponent-unset" onClick={stopEvent}>
                                    <div>Drag & Drop your document{props.multiple ? 's' : ''} here</div>
                                    <div>or</div>
                                    {renderBrowseFiles()}
                                    {renderSupportedFileTypes()}
                                </div>
                            )}
                            {/* Success */}
                            {fileStatus === FILE_STATUSES.success && (
                                <div className="dragDropComponent-success" onClick={stopEvent} title={filename}>
                                    <img src="/images/icons/smallDoc.png" alt="document icon" style={{ marginRight: '5px' }} />
                                    <span>{filename}</span>
                                    <img
                                        alt="cancel icon"
                                        className="dragDropComponent-success-cancel"
                                        src="/images/icons/cancel.png"
                                        onClick={(e) => {
                                            stopEvent(e);
                                            setFileStatus(FILE_STATUSES.unset);
                                            props.clearFile();
                                        }}
                                    />
                                </div>
                            )}
                            {/* processing */}
                            {fileStatus === FILE_STATUSES.processing && (
                                <div className="dragDropComponent-processing" onClick={stopEvent}>
                                    <div>Processing file....</div>
                                    <div className="dragDropComponent-processingBar">
                                        <div />
                                    </div>
                                </div>
                            )}
                            {/* error */}
                            {fileStatus === FILE_STATUSES.error && (
                                <div className="dragDropComponent-error" onClick={stopEvent}>
                                    <div>Unsupported file format</div>
                                    <div>Please try another file.</div>
                                    {renderBrowseFiles()}
                                    {renderSupportedFileTypes()}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Dropzone>
        </div>
    );
};
