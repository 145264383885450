import React, { Component } from 'react';
import '../styles/tabSwitcher.css';

const isMobile = window.innerWidth <= 768;

export default class TabSwitcher extends Component {
    /**
     * A row of tabs that can be switched between!
     *
     * required props:
     * - items: array of objects with a "title" property.
     * - key: a string to specify this TabSwitcher
     * - onSelect: a function which takes an index and the corresponding element from items to call upon selecting a new
     *             tab.
     */

    state = {
        tabIndexSelected: undefined,
    };

    // strKeyToIdx = (key) => {
    //     return this.props.items.find((i) => i.title === key)
    // }

    componentDidMount = () => {
        const p = this.props;
        const selected = p.defaultSelected;
        if (selected !== null && selected !== undefined) {
            this.clickTab(p.items[selected], selected);
        }
    };

    componentDidUpdate = (prevProps) => {
        const p = this.props;
        const defaultSelected = p.defaultSelected;
        if (prevProps.defaultSelected !== defaultSelected) {
            this.clickTab(p.items[defaultSelected], defaultSelected);
        }

        if (this.props.selectedTabTitle) {
            const idx = this.props.items.findIndex((i) => i.title === this.props.selectedTabTitle);
            if (idx !== -1 && idx !== this.state.tabIndexSelected) {
                this.setState({ tabIndexSelected: idx });
            }
        }
    };

    clickTab = async (item, index) => {
        const p = this.props;
        const makeUpdate = () => this.setState({ tabIndexSelected: index }, () => p.onSelect?.(index, item));
        makeUpdate();
    };

    renderTab = (item, index) => {
        const key = `tabSwitcherTab_${index}_${this.props.key}`;
        const className = 'tab ' + (this.state.tabIndexSelected === index ? 'tab-active' : 'tab-inactive') + (item.disabled ? ' tab-disabled' : '');
        const title = `${item.title}${item.titleSuffix ? ` ${item.titleSuffix}` : ''}`;
        return (
            <div
                key={key}
                style={{
                    whiteSpace: 'nowrap',
                    zIndex: 1,
                }}
                className={className}
                onClick={item.disabled ? null : () => this.clickTab(item, index)}
            >
                {title}{' '}
            </div>
        );
    };

    renderTabs = (items) => {
        const ret = [];
        for (let i = 0; i < items.length; i++) {
            ret.push(this.renderTab(items[i], i));
        }
        return ret;
    };

    render = () => {
        return <div className="tab-switcher">{this.renderTabs(this.props.items)}</div>;
    };
}
