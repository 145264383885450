import React from 'react';
import Select, { StylesConfig, ActionMeta } from 'react-select';

import '../../styles/objectFilterSelect.css';

interface OptionType {
    value: any;
    label: string;
}

interface ObjectFilterSelectProps {
    objects?: any[];
    getLabel: (obj: any) => string;
    noDefaultOption?: boolean;
    defaultLabel?: string;
    matchProperty?: string;
    selected?: any;
    width?: string | number;
    height?: string | number;
    containerStyle?: React.CSSProperties;
    placeholder?: string;
    onChange?: (option: OptionType | null, actionMeta: ActionMeta<OptionType>) => void;
    filterOptionFunc?: (option: OptionType, inputValue: string) => boolean;
    isDisabled?: boolean;
}

export const ObjectFilterSelect: React.FC<ObjectFilterSelectProps> = (props) => {
    const options: OptionType[] =
        props.objects?.map((obj) => ({
            value: obj,
            label: props.getLabel(obj),
        })) || [];

    if (!props.noDefaultOption) {
        options.unshift({
            value: null,
            label: props.defaultLabel ?? 'All',
        });
    }

    const displayValue = props.matchProperty
        ? options.find((option) => option.value?.[props.matchProperty!] === props.selected?.[props.matchProperty!])
        : options.find((option) => option.value === props.selected);

    const customStyles: StylesConfig<OptionType> = {
        control: (provided) => ({
            ...provided,
            width: props.width,
            height: props.height,
        }),
        menu: (provided) => ({
            ...provided,
            width: props.width,
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: `${props.height} !important`,
            padding: '0 0 0 10px',
        }),
        input: (provided) => ({
            ...provided,
            height: `${props.height} !important`,
            margin: '0',
            top: 0,
        }),
    };

    return (
        <div
            style={{
                position: 'relative',
                width: props.width,
                height: props.height,
                ...props.containerStyle,
            }}
        >
            <Select
                className="userFilterSelect-select"
                placeholder={props.placeholder ?? 'Filter by user'}
                options={options}
                value={displayValue}
                onChange={(newValue, actionMeta) => {
                    if (props.onChange) {
                        props.onChange(newValue as OptionType | null, actionMeta);
                    }
                }}
                styles={customStyles}
                filterOption={props.filterOptionFunc}
                isDisabled={props.isDisabled}
            />
        </div>
    );
};
