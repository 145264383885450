import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api2 from '../../../../api2';
import { AccountType, AccountIdType } from '../../../../types/Account';
import validator from 'validator';
import { stateList } from '../../../../types/States';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';

export interface AccountUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    user?: string;
    name?: string;
    account_type?: string;
    id_type?: string;
    id_number?: string;

    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
}

export interface AccountUploadHandlerConfig extends BulkUploadHandlerConfig {
    investment?: string;
}

const accountTypeField = sharedFieldEnum(Object.values(AccountType).map((val) => capitalizeFirstEveryWord(val)));

const idTypeField = sharedFieldEnum(Object.values(AccountIdType));
const stateField = sharedFieldEnum(stateList);

class AccountUploadHandler extends BulkUploadHandler<AccountUploadHandlerCreateType, AccountUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'account' or 'valuation'
    type = BulkUploadType.account;

    // The base columns that are required for the bulk upload
    base_columns = {
        user: {
            ...sharedFields.object_id,
            displayName: 'User ID',
            fieldName: 'user',
            required: true,
        },
        name: {
            ...sharedFields.string,
            displayName: 'Account Name',
            fieldName: 'name',
            required: true,
        },
        account_type: {
            ...accountTypeField,
            displayName: 'Account Type',
            fieldName: 'account_type',
            isValid: (val: string) => accountTypeField.isValid(val.toLowerCase()),
            format: (val: string) => Object.values(AccountType).find((key) => key.toLowerCase() === val.toLowerCase()) || '',
            style: {
                ...accountTypeField.style,
                width: '150px',
            },
            required: false,
        },
        id_type: {
            ...idTypeField,
            displayName: 'ID Type',
            fieldName: 'id_type',
            isValid: (val: string) => idTypeField.isValid(val.toUpperCase()),
            format: (val: string) => Object.values(AccountIdType).find((key) => key.toLowerCase() === val.toLowerCase()) || '',
            required: false,
        },
        id_number: {
            ...sharedFields.string,
            displayName: 'ID Number',
            fieldName: 'id_number',
            isValid: (val: string) => sharedFields.string.isValid(val) && validator.isAlphanumeric(val),
            required: false,
        },

        streetLine1: {
            ...sharedFields.string,
            displayName: 'Street Line 1',
            fieldName: 'streetLine1',
            required: false,
        },
        streetLine2: {
            ...sharedFields.string,
            displayName: 'Street Line 2',
            fieldName: 'streetLine2',
            required: false,
        },
        city: {
            ...sharedFields.string,
            displayName: 'City',
            fieldName: 'city',
            required: false,
        },
        state: {
            ...stateField,
            displayName: 'State',
            fieldName: 'state',
            isValid: (val: string) => stateField.isValid(val.toUpperCase()),
            format: (val: string) => stateField.format(val.toUpperCase()),
            style: { ...stateField.style, width: '85px' },
            required: false,
        },
        zipCode: {
            ...sharedFields.string,
            displayName: 'Zip Code',
            fieldName: 'zipCode',
            style: { ...stateField.style, width: '100px' },
            required: false,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof AccountUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof AccountUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof AccountUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof AccountUploadHandlerCreateType]: string }, columnOrder?: (keyof AccountUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof AccountUploadHandlerCreateType)[]): AccountUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        return {
            user: expectedColumns.user && expectedColumns.user.isValid(parsedValues.user) ? parsedValues.user : '',
            name: expectedColumns.name && expectedColumns.name.isValid(parsedValues.name) ? parsedValues.name : '',
            account_type: expectedColumns.account_type && expectedColumns.account_type.isValid(parsedValues.account_type) ? parsedValues.account_type : '',
            id_type: expectedColumns.id_type && expectedColumns.id_type.isValid(parsedValues.id_type) ? parsedValues.id_type : '',
            id_number: expectedColumns.id_number && expectedColumns.id_number.isValid(parsedValues.id_number) ? parsedValues.id_number : '',

            streetLine1: expectedColumns.streetLine1 && expectedColumns.streetLine1.isValid(parsedValues.streetLine1) ? parsedValues.streetLine1 : '',
            streetLine2: expectedColumns.streetLine2 && expectedColumns.streetLine2.isValid(parsedValues.streetLine2) ? parsedValues.streetLine2 : '',
            city: expectedColumns.city && expectedColumns.city.isValid(parsedValues.city) ? parsedValues.city : '',
            state: expectedColumns.state && expectedColumns.state.isValid(parsedValues.state) ? parsedValues.state : '',
            zipCode: expectedColumns.zipCode && expectedColumns.zipCode.isValid(parsedValues.zipCode) ? parsedValues.zipCode : '',
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof AccountUploadHandlerCreateType)[]
    ): Promise<FileParseResult<AccountUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<AccountUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<AccountUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof AccountUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        try {
            // Attempt to create a single account from the current object.
            const account_id = (
                await api2.client.AccountApi.createAccount({
                    CreateAccountRequest: {
                        user: data.user,
                        name: data.name,
                        account_type: data.account_type ? data.account_type.toLowerCase() : undefined,
                        id_type: data.id_type ? data.id_type.toUpperCase() : undefined,
                        id_number: data.id_number,

                        address: {
                            streetLine1: data.streetLine1,
                            streetLine2: data.streetLine2,
                            city: data.city,
                            state: data.state,
                            zipCode: data.zipCode,
                        },
                    },
                })
            ).data.account_id;

            // Return the new account ID.
            return {
                success: true,
                message: 'Account created successfully',
                id: account_id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating account:', err);
            return {
                success: false,
                message: `Error creating account: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api2.client.AccountApi.deleteAccount({
                account_id: id,
            });
            return {
                success: true,
                message: 'Account deleted successfully',
            };
        } catch (err) {
            console.log('error deleting account:', err);
            return {
                success: false,
                message: `Error deleting account: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the AccountUploadHandler
export const getAccountUploadHandler: (config: AccountUploadHandlerConfig) => AccountUploadHandler = (config: AccountUploadHandlerConfig) => new AccountUploadHandler(config);

export default AccountUploadHandler;
