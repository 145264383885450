import { useState } from 'react';
import api2 from '../../../api2';
import { VUES } from '../../../constants/constantStrings';
import routes from '../../../routes';
import { formatUTCDate } from '../../../utilities/format/formatDate';

import styles from '../../../styles/documentDrawerRelatedDocs.module.scss';
import { Document, DocumentType } from '../../../types/Document';
import { UserAccess } from '../../../types/User';

const NO_PREVIOUS_DOCUMENT_MESSAGE = 'No previous document found';

interface DocumentDrawerRelatedDocsProps {
    document: Document;
}

export const DocumentDrawerRelatedDocs = (props: DocumentDrawerRelatedDocsProps) => {
    const [relatedDocuments, setRelatedDocuments] = useState<Document[] | null>(null);
    const [showAllRelatedDocuments, setShowAllRelatedDocuments] = useState(false);

    // fetch the related documents
    const fetchRelatedDocuments = async (doc: Document) => {
        // get all the params
        const user_id = doc.user?._id as string;
        const doc_type = doc.type as DocumentType;
        const form_type = doc.metadata?.find((m) => m.key === 'form type')?.value;

        // must have user_id and doc_type
        if (!user_id || !doc_type) return;

        // fetch the past 5 and next 5 documents
        try {
            const [previousDocuments, nextDocuments] = await Promise.all([
                api2.client.DocumentApi.listDocuments({
                    users: [user_id],
                    ownership_type: doc.ownership_type || 'null',
                    ownership_ids: doc.ownership_ids?.map((o) => o._id),
                    types: [doc_type],
                    ...(form_type ? { form_types: [form_type] } : {}),
                    end_date: doc.date,
                    populate_user: true,
                    populate_ownership: true,
                    sort_field: 'date',
                    sort_direction: 'desc',
                    limit: 5,
                }).then((response) => response.data.documents as Document[]),

                api2.client.DocumentApi.listDocuments({
                    users: [user_id],
                    ownership_type: doc.ownership_type || 'null',
                    ownership_ids: doc.ownership_ids?.map((o) => o._id),
                    types: [doc_type],
                    ...(form_type ? { form_types: [form_type] } : {}),
                    start_date: doc.date,
                    populate_user: true,
                    populate_ownership: true,
                    sort_field: 'date',
                    sort_direction: 'desc',
                    limit: 5,
                }).then((response) => response.data.documents as Document[]),
            ]);

            // combine the previous and next documents and make unique by ._id using filter
            const combinedDocuments = [...nextDocuments, ...previousDocuments].filter((doc, index, self) => index === self.findIndex((d) => d._id === doc._id));

            // set the state
            setRelatedDocuments(combinedDocuments);
        } catch (e) {
            console.error(e);
        }
    };

    // get the previous document
    const getPreviousDocument = () => {
        if (!relatedDocuments) return null;

        // order the documents by date descending
        const orderedDocumentsDescending = relatedDocuments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        // find the index of the current document
        const currentDocIndex = orderedDocumentsDescending.findIndex((d) => d._id === props.document._id);

        // return the next document in the list, or null if it's the last one
        const previousDocument = currentDocIndex !== -1 && currentDocIndex < orderedDocumentsDescending.length - 1 ? orderedDocumentsDescending[currentDocIndex + 1] : null;

        return previousDocument;
    };

    // get the shared style for the related documents
    const getSharedStyle = () => {
        return {
            backgroundColor: 'white',
            marginBottom: '5px',
            width: '100%',
            padding: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            textDecoration: 'none',
        };
    };

    const doc = props.document;
    if (!doc?._id) return null; // if just creating, no previous documents

    // render the title based on whether we're showing all related documents or just the previous one
    const renderTitle = () => (showAllRelatedDocuments ? 'All Related Documents' : 'Previous Document');

    // if not fetched yet, show button to fetch
    if (relatedDocuments === null) {
        return (
            <div className="docDrawer-section">
                <div className="docDrawer-title">{renderTitle()}</div>
                <button onClick={() => fetchRelatedDocuments(doc)}>Load Previous Document</button>
            </div>
        );
    }

    const previousDocument = getPreviousDocument();

    let documentsToShow = [...(showAllRelatedDocuments ? relatedDocuments : [previousDocument])].filter((d) => d !== null) as Document[]; // remove nulls

    // render the header, including a button to show all related documents
    const renderHeader = () => {
        return (
            <div className={`docDrawer-title ${styles.singleDocTitle}`}>
                {renderTitle()}
                {relatedDocuments?.length === 0 ? null : (
                    <span className={`a ${styles.singleDocShowAll}`} onClick={() => setShowAllRelatedDocuments(!showAllRelatedDocuments)}>
                        {showAllRelatedDocuments ? 'Show previous document only' : 'Show surrounding documents'}
                    </span>
                )}
            </div>
        );
    };

    // if no related documents, show a message
    // if (docsToShow.length === 0) {
    //     return (
    //         <div className="docDrawer-section">
    //             {renderHeader()}
    //             <div style={{ ...getSharedStyle() }}>{NO_PREVIOUS_DOCUMENTS_MESSAGE}</div>
    //         </div>
    //     );
    // }

    const renderSingleDocContent = (doc: Document) => {
        const form_type = doc.metadata?.find((m) => m.key === 'form type')?.value;

        return (
            <div className={styles.singleDoc}>
                <div className={styles.singleDocTypeRow}>
                    <div>
                        {doc.type}
                        {form_type && ` (${form_type})`}
                    </div>
                    <div>{formatUTCDate(doc.date)}</div>
                </div>

                <div className={styles.singleDocName}>{doc.name}</div>
            </div>
        );
    };

    return (
        <div className={`docDrawer-section ${styles.main}`}>
            {renderHeader()}

            {documentsToShow.length === 0 ? (
                <div style={{ ...getSharedStyle() }}>{NO_PREVIOUS_DOCUMENT_MESSAGE}</div>
            ) : (
                documentsToShow.map((doc) => {
                    const isCurrentDoc = doc._id === props.document?._id;
                    const user_id = doc.user?._id;
                    const document_path = isCurrentDoc
                        ? null
                        : routes.client.viewDocument({
                              access: VUES.ADMIN as UserAccess,
                              documentId: doc._id,
                              userId: user_id as string,
                          });

                    // style based on whether it's the current document or not
                    const relatedDocStyle = {
                        ...(isCurrentDoc ? { cursor: 'default' } : null),
                        ...(isCurrentDoc ? { backgroundColor: '#e0e0e0' } : {}),
                    };

                    const docContent = renderSingleDocContent(doc);

                    return isCurrentDoc ? (
                        <div key={doc._id} className={styles.sharedStyle} style={relatedDocStyle}>
                            {docContent}
                        </div>
                    ) : (
                        <a
                            key={doc._id}
                            className={styles.sharedStyle}
                            style={relatedDocStyle}
                            href={document_path ?? undefined}
                            target="_blank"
                            rel="noreferrer"
                            title="View document in new tab"
                        >
                            {docContent}
                        </a>
                    );
                })
            )}
        </div>
    );
};
