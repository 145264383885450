import React, { useState, useEffect } from 'react';
import '../../styles/multitoggle.css';

interface MultitoggleProps {
    options: string[];
    selection?: string | null;
    defaultSelected?: string;
    onSelect?: (opt: string, idx: number, opts: string[]) => void;
    keyStr?: string;
    renderOption?: (opt: string, idx: number, opts: string[]) => React.ReactNode;
    optionStyle?: React.CSSProperties;
    disabled?: boolean;
    titleHover?: boolean;
    renderTitle?: (opt: string, idx: number, opts: string[]) => string;
    zIndex?: number;
    containerStyle?: React.CSSProperties;
    selectedOptionStyle?: React.CSSProperties;
}

const Multitoggle: React.FC<MultitoggleProps> = ({
    options,
    selection,
    defaultSelected,
    onSelect,
    keyStr,
    renderOption,
    optionStyle,
    disabled,
    titleHover,
    renderTitle,
    zIndex,
    containerStyle,
    selectedOptionStyle,
}) => {
    const getSelectedIndex = (props: MultitoggleProps) => {
        if (props.selection !== undefined) {
            return props.options.findIndex((o) => o === props.selection);
        } else if (props.defaultSelected) {
            return props.options.findIndex((o) => o === props.defaultSelected);
        }
        return -1;
    };

    const [selectionIdx, setSelectionIdx] = useState<number>(getSelectedIndex({ options, selection, defaultSelected }));

    useEffect(() => {
        setSelectionIdx(getSelectedIndex({ options, selection, defaultSelected }));
    }, [selection, defaultSelected, options]);

    const handleOptionClick = (opt: string, idx: number, opts: string[]) => {
        if (!disabled) {
            setSelectionIdx(idx);
            onSelect?.(opt, idx, opts);
        }
    };

    const renderOptionElement = (opt: string, idx: number, opts: string[]) => {
        const key = `toggleBarOpt_${keyStr ?? ''}${idx}`;
        const inner = renderOption ? renderOption(opt, idx, opts) : opt;
        const style = {
            width: `${100 / options.length}%`,
            zIndex: zIndex ?? 0,
            ...optionStyle,
            ...(disabled ? { cursor: 'not-allowed' } : {}),
        };

        return (
            <div
                key={key}
                className="optionContainer"
                style={style}
                onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(opt, idx, opts);
                }}
                title={titleHover ? (renderTitle?.(opt, idx, opts) ?? String(inner)) : ''}
            >
                {inner}
            </div>
        );
    };

    const renderOptions = () => options.map((opt, idx, opts) => renderOptionElement(opt, idx, opts));

    const sliderStyle = {
        width: `${100 / options.length}%`,
        left: disabled ? '0%' : `${selectionIdx * (100 / options.length)}%`,
        ...selectedOptionStyle,
    };

    return (
        <div className="toggleBar" style={containerStyle}>
            <div className="slider" style={sliderStyle} />
            {renderOptions()}
        </div>
    );
};

export default Multitoggle;
