// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/performanceOverview.css';
import { EM_DASH } from '../../constants/constantStrings';
import formatCurrency from '../../utilities/format/formatCurrency';
import { calcAmountInvested } from '../../utilities/calculate/financeCalculations';

const isMobile = window.innerWidth <= 768;

class PortfolioOverview extends Component {
    componentDidMount = () => {};

    componentDidUpdate = (prev) => {};

    sum = (arr, func) => arr.reduce((acc, x) => acc + func(x), 0);

    getData = () => {
        const p = this.props;
        if (!p.investments || !p.investments.length) return [];
        const committed = this.sum(p.investments, (i) => i.performance?.itd?.committed ?? 0);
        const invested = calcAmountInvested(p.investments);
        const distributions = this.sum(p.investments, (i) => i.performance?.itd?.distributions ?? 0);
        const curValue = this.sum(p.investments, (i) => i.valuation ?? 0);
        const fees = this.sum(p.investments, (i) => (i.performance?.itd?.fees ? i.performance?.itd?.fees : 0));
        return [
            {
                label: 'Total Commitments',
                value: () => formatCurrency(committed),
            },
            {
                label: 'Amount Invested',
                value: () => {
                    return formatCurrency(invested);
                },
            },
            {
                label: '% Called',
                value: () => {
                    const percentCalled = (invested / committed) * 100;
                    return isFinite(percentCalled) ? `${percentCalled.toFixed(2)}%` : EM_DASH;
                },
            },
            {
                label: 'Total Distributions',
                value: () => {
                    return formatCurrency(distributions);
                },
            },
            {
                label: 'Current Portfolio Value',
                value: () => {
                    return formatCurrency(curValue);
                },
            },
            {
                label: 'Total Portfolio Value',
                value: () => {
                    return formatCurrency(distributions + curValue);
                },
            },
            {
                label: 'Annualized Return',
                value: () => {
                    return `${((p.account?.performance?.irr_by_account ?? p.relevantUser?.performance?.irr_by_user ?? 0) * 100).toFixed(2)}%`;
                },
            },
            {
                label: 'Fees / Total Amount Invested',
                value: () => {
                    return invested ? `${((fees / invested) * 100).toFixed(2)}%` : EM_DASH;
                },
            },
        ];
    };

    render = () => {
        const data = this.getData();
        return (
            <div className="widget PortfolioOverview_component" style={{ position: 'relative', height: '100%' }}>
                <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Portfolio Overview</h1>

                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                        {data.map((d, dIdx) => {
                            return (
                                <tr key={`tr_${dIdx}`} style={{ position: 'relative', height: '40px', fontSize: '18px' }}>
                                    <td style={{ width: '70%', fontWeight: 'bold' }}>{d.label}</td>
                                    <td style={{ width: '30%' }}>{d.value()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };
}

export default withRouter(PortfolioOverview);
