import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadDocumentFromUrl } from '../utilities/file/downloadDocument';
import sanitizeFilename from '../utilities/format/sanitizeFilename';
import { faDownload, faEnvelope, faEnvelopeCircleCheck, faEnvelopeOpen, faEnvelopeOpenText, faEnvelopeSquare, faSquareEnvelope, faX } from '@fortawesome/free-solid-svg-icons';

import styles from '../styles/singleAccountRalDisplay.module.scss';
import { downloadDocument, getFullDocFilename } from '../utilities/documents/downloadDocument';

const SingleAccountRalDisplay = ({ ralRequest, account, parentConnection }) => {
    const isSent = ralRequest.sent_at;
    const ralDocId = ralRequest.signed_ral?._id || ralRequest.signed_ral;

    const getRalDocName = () => {
        if (!ralRequest.signed_ral) return null;
        return `${sanitizeFilename(parentConnection.name)}__${sanitizeFilename(parentConnection.user_name || parentConnection.user?.name || 'UnknownUser')}__${sanitizeFilename(ralRequest.signed_ral.name)}__RAL.pdf`;
    };

    // build filename if ralDocId exists
    let docName = getRalDocName();

    const ownerNames = ralRequest.ownership_ids?.map((obj) => obj.name) || [];
    const ownerCount = ownerNames.length;

    const ownershipTypeStr = ralRequest.ownership_type === 'Account' ? 'Entity' : ralRequest.ownership_type;

    // count string, if ownership_type is investment or account
    // if ownership_ids is empty, then it's a client
    const description = ownerCount === 0 ? null : `${ownershipTypeStr}: ${ownerNames[0]}${ownerCount > 1 ? ` (+ ${ownerCount - 1} ${ownerCount !== 2 ? 's' : ''})` : ''}`;

    const fullDescription = `${ownershipTypeStr}: ${ownerNames.join(', ')}`;

    const downloadRAL = async () => {
        const url = ralRequest?.reporting_auth_urls?.downloadUrl;
        if (url) {
            await downloadDocumentFromUrl(url, docName, true);
        } else {
            try {
                downloadDocument(ralDocId, docName);
            } catch (e) {
                console.error('Error downloading RAL', e);
            }
        }
    };

    return (
        <div
            key={`ral-${ralRequest.name}-${ralDocId}-${parentConnection.name}`}
            style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
            }}
            title={`${ralDocId ? 'RAL Signed' : isSent ? 'RAL Sent' : 'RAL Not Sent'} for ${description}`}
        >
            {ralDocId ? (
                <FontAwesomeIcon
                    title={`Download Signed RAL for ${fullDescription}`}
                    icon={faDownload}
                    size="1x"
                    className={styles.download}
                    style={{ margin: '0px', marginRight: '5px' }}
                    // onClick={() => downloadDocument(ralDocId, docName)}
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadRAL();
                    }}
                />
            ) : isSent ? (
                <FontAwesomeIcon title={`RAL Sent for ${fullDescription}`} icon={faEnvelope} size="1x" className={styles.envelope} style={{ margin: '0px', marginRight: '5px' }} />
            ) : (
                <FontAwesomeIcon title={`No RAL for ${fullDescription}`} icon={faX} size="1x" className={styles.x} style={{ margin: '0px', marginRight: '5px' }} />
            )}

            <div className="table-ellipses" style={{ width: '250px', marginLeft: '5px' }}>
                {ownerCount > 0 ? description : 'User'}
            </div>
        </div>
    );
};

export default SingleAccountRalDisplay;
