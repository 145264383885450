// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../../styles/widget.css';
import '../../../styles/performanceOverview.css';
import { EM_DASH } from '../../../constants/constantStrings';

const isMobile = window.innerWidth <= 768;

class PerformanceOverview extends Component {
    getIndividualWidth = () => {
        let amount = this.FIELDS.filter((f) => typeof this.props[f] !== 'undefined').length;
        return `${(1 / amount) * 100}%`;
    };

    /**
     *
     * @param {string} propName name of property displayed by this field
     * @param {string} label label to display to the user on desktop
     * @param {string} mobileLabel label to display to the user on mobile
     * @param {"percentage" | "multiple"} style whether to display as a percentage
     *  or a multiple
     * @param {boolean} isLast false to display divider afterwatds,
     *  true not to
     */
    renderField = (propName, label, mobileLabel, style, isLast) => {
        let outerClass = `performanceOverview_metric ${isLast ? 'performanceOverview_metricDivider' : ''}`;
        let data = this.props[propName];
        let dataDisplay;
        if (!Number.isFinite(data)) {
            dataDisplay = EM_DASH;
        } else if (data > 1e12) {
            dataDisplay = style === 'percentage' ? `${(100 * (data.toExponential(1) ?? 0)).toFixed(1)}%` : `${(data.toExponential(1) ?? 0).toFixed(1)}x`;
        } else {
            dataDisplay = style === 'percentage' ? `${(100 * (data ?? 0)).toFixed(1)}%` : `${(data ?? 0).toFixed(1)}x`;
        }

        return (
            typeof this.props[propName] !== 'undefined' && (
                <div
                    key={(this.props.key ?? 'performance_widget_') + propName}
                    className={`performanceOverview_metric ${isLast ? '' : 'performanceOverview_metricDivider'}`}
                    style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <div className="performanceOverview_metricKey" style={{ fontSize: dataDisplay.length > 20 ? '15px' : dataDisplay.length > 10 ? '20px' : null }}>
                        {dataDisplay}
                    </div>
                    <div className="performanceOverview_metricValue">{isMobile ? mobileLabel : label}</div>
                </div>
            )
        );
    };

    /**
     * Determines which field on the widget is displayed last
     */
    findLast = () => {
        const arr = this.FIELDS;
        for (let i = 0; i < arr.length; i++) {
            let field = arr[i];
            if (i + 1 === arr.length) {
                return field;
            } else if (typeof this.props[field] !== 'undefined') {
                return field;
            }
        }
    };

    FIELDS = ['called', 'roi', 'moic', 'irr'];

    STYLE_MATCH = {
        called: 'percentage',
        moic: 'multiple',
        roi: 'percentage',
        irr: 'percentage',
    };

    LABELS = {
        irr: 'annualized return',
        moic: 'multiple on invested capital',
        roi: 'return on investment',
        called: '% capital called',
    };

    MOBILE_LABELS = {
        irr: 'IRR',
        moic: 'MOIC',
        roi: 'ROI',
        called: '% called',
    };

    render = () => {
        const last = this.findLast();
        return (
            <div className="widget performanceOverview_component" style={{ '--performance-overview-metric-width': this.getIndividualWidth() }}>
                {['irr', 'moic', 'roi', 'called'].map((prop) => {
                    return this.renderField(prop, this.LABELS[prop], this.MOBILE_LABELS[prop], this.STYLE_MATCH[prop], last === prop);
                })}
            </div>
        );
    };
}

export default withRouter(PerformanceOverview);
