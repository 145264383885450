import { tr } from 'date-fns/locale';
import { useState, useEffect } from 'react';

/**
 * Tracks
 * @param keys array of objects or strings
 *  if object, must be of form {inputKeys: [string], outputKey: string}
 *  otherwise, string that is the key to track, will be input and output
 * @returns map of key to boolean
 */
export const useHotKeys = ({ keys }) => {
    const [trackedKeys, setTrackedKeys] = useState([]);
    const [hotkeyMap, setHotkeyMap] = useState({});

    // turn keys into trackedKeys
    useEffect(() => {
        let newTrackedKeys = [];

        keys.forEach((k) => {
            // if is string
            if (typeof k === 'string') {
                newTrackedKeys.push({
                    inputKeys: [k],
                    outputKey: k,
                });
            }
            // if is object
            else if (typeof k === 'object') {
                newTrackedKeys.push({
                    inputKeys: k.inputKeys,
                    outputKey: k.outputKey,
                });
            }
        });

        setTrackedKeys(newTrackedKeys);
    }, [keys]);

    useEffect(() => {
        const handler = (e, down) => {
            const keyObj = trackedKeys.find((k) => k.inputKeys.includes(e.key));
            const keyChanged = hotkeyMap[e.key] !== down;
            if (!keyObj || !keyChanged) return;
            setHotkeyMap((prev) => {
                return { ...prev, [keyObj.outputKey]: down };
            });
        };

        const handleKeyDown = (e) => handler(e, true);
        const handleKeyUp = (e) => handler(e, false);

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [trackedKeys, hotkeyMap]);

    return hotkeyMap;
};

export const withHotKeys = (Component, targetKeys) => {
    return (props) => {
        const hotkeyMap = useHotKeys({ keys: targetKeys });
        return <Component {...props} hotkeyMap={hotkeyMap} />;
    };
};
