// check if a Staging area object can be imported
// obj: staging area object
// type: type of object, like 'user', 'account', 'investment', etc
// parentType2IdMap: map of parent types to parent object ids
// // eg. { user: 'asldkjflasdkfasldf', account: 'asldkjflasdkfasldf' }
export const canImport = (obj, type, parentType2IdMap = {}) => {
    // see if should import
    const isSaved = !obj.modified;
    const isMapped = obj[type];
    let parentIsMapped = false || type === 'user';
    for (let [parentType, parentObj] of Object.entries(parentType2IdMap)) {
        if (parentObj && parentObj[parentType]) {
            parentIsMapped = true;
            break;
        }
    }
    const allowImport = parentIsMapped && !isMapped && isSaved;
    return allowImport;
};
