import React from 'react';

import Select from 'react-select';
import '../../styles/objectFilterSelect.css';

import Dropdown from '../Dropdowns/Dropdown';
import { DrawerDateInput } from './DrawerDateInput';
import renderInputError from '../renderInputError';
import formatCurrency from '../../utilities/format/formatCurrency';
import Checkbox from '../Inputs/Checkbox';
import DropdownSection from './DropdownSection';
import ReactDatePicker from 'react-datepicker';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/drawerInputObject2.css';
import '../../styles/react-datepicker.css';
import _ from 'lodash';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';
import { PLEASE_SELECT___ } from '../../constants/constantStrings';
import { CURRENCIES, CURRENCY_SYMBOLS } from '../../constants/currencyConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Multitoggle from '../Toggles/Multitoggle';
import { TimelessDate } from '../../types/TimelessDate';

const PLACEHOLDER_COLOR = '#aaa';

export const DrawerInputObject2 = (props) => {
    const currencyInput = React.useRef(null);
    const percentInput = React.useRef(null);

    const [isCurrencyFocused, setCurrencyFocused] = React.useState(false);
    const [isPercentFocused, setPercentFocused] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    let objectHeight = props.compactMode ? 30 : 38;
    const spacerSize = props.spacerSize ?? (props.compactMode ? 3 : 10);
    // const objectHeight = '18px'

    const inlineLabel = props.inlineLabel || props.object.inlineLabel;

    let component = undefined;

    const {
        disabled: objIsDisabled,
        placeholder: objPlaceholder,
        optionField: objOptionField,
        getOptionLabel: objGetOptionLabel,
        type: objType,
        subtype: objSubtype,
        optionClearable: objOptionClearable,
        defaultOptionString: objDefaultOptionString,
        component: objCustomComponent,
        required: objRequired,
        fieldName: objFieldName,
        label: objLabel,
        hideLabel: objHideLabel,
        labelStyle: objLabelStyle,
        options: objOptions,
    } = props.object;

    const { readOnly } = props;

    // corrects certain data types
    // calls onChangeCallback and object callback
    const onChange = (val) => {
        if (props.object.isLocked) return;

        const isNotNonNegativeNumber = (objType === 'number' && objSubtype === 'nonNegative' && val < 0) || val === '-';
        // reject non numbers, unless they're "-" or "+" only
        const isInvalidNumber = objType === 'number' && isNaN(val) && val !== '-' && val !== '+';
        // reject non numbers, unless they're "-" or "+" only
        const isInvalidPercent = objType === 'percent' && isNaN(val) && val !== '-' && val !== '+';
        // reject non numbers, unless they're "-" or "+" only
        const isInvalidCurrency = objType === 'currency' && isNaN(val) && val !== '-' && val !== '+' && val !== '.';
        if (isInvalidNumber || isInvalidPercent || isInvalidCurrency || isNotNonNegativeNumber) return;

        (async () => {
            if (!props.object.preventOnChangeCallback) {
                const ___ = await props?.onChangeCallback?.(val);
            }
            const _ = await props.object.callback?.(val);
        })();
        // props?.onChangeCallback?.(val)
        // props.object.callback?.(val)
    };

    const noValue = [null, undefined, ''].includes(props.value);
    const formatValue = (val, type, disabled) => {
        if (noValue) {
            if (objOptionClearable) {
                return null;
            }
            return '';
        }

        if (props.object.format) {
            return props.object.format(val);
        }
        // format currency if disabled or if not focused
        // otherwise, show editable value
        const isUnfocusedOrDisabledCurrency = type === 'currency' && (!isCurrencyFocused || disabled);
        const isUnfocusedOrDisabledPercent = type === 'percent' && (!isPercentFocused || disabled);

        if (isUnfocusedOrDisabledCurrency) val = formatCurrency(Number(val), props.object.currency);
        else if (isUnfocusedOrDisabledPercent) val = val + '%';

        return val;
    };
    const value = formatValue(props.value, objType, objIsDisabled);

    // option component, uses Dropdown
    // options is a list of strings or objects
    // if objects, optionField is required
    if (['option', 'option-section'].includes(objType)) {
        // if value is null or undefined, use placeholder
        let selection = noValue ? (objOptionField ? { [objOptionField]: objPlaceholder } : objPlaceholder) : value;

        // if getOptionLabel is provided, then it's a list of objects
        // and needs to be in the format of {value, label}
        let optionsToUse = objOptions;
        let optionFieldToUse = objOptionField;
        if (objGetOptionLabel) {
            optionsToUse =
                objOptions?.map((opt) => {
                    return {
                        value: opt,
                        label: objGetOptionLabel(opt),
                    };
                }) ?? [];

            optionFieldToUse = 'label';

            selection = {
                value: noValue ? null : selection,
                label: noValue ? selection : objGetOptionLabel(selection),
            };
        }

        const fontColor = noValue ? PLACEHOLDER_COLOR : null;
        const selectFunc = (value) => {
            if (objGetOptionLabel) {
                value = value.value;
            }
            if (value === PLEASE_SELECT___) {
                if (objOptionClearable) onChange(null);
            } else {
                onChange(value);
            }
        };
        // component = <div style={{ textAlign: 'left' }}>
        component = (
            <div>
                <Dropdown
                    selection={selection}
                    fontColor={fontColor}
                    options={optionsToUse}
                    optionField={optionFieldToUse}
                    select={selectFunc}
                    style={{ height: objectHeight - 2 + 'px' }}
                    readOnly={readOnly}
                />
            </div>
        );
    }

    // select component, uses react-select
    if (['select'].includes(objType)) {
        // convert options to react-select format
        let options =
            objOptions?.map((opt) => {
                let label = opt;

                if (props.object.getLabel) {
                    label = props.object.getLabel(opt);
                    // label = <span
                    //     style={{
                    //         whiteSpace: 'nowrap',
                    //         overflow: 'hidden',
                    //     }}
                    // >{props.object.getLabel(opt)}</span>
                } else if (objOptionField) {
                    label = opt[objOptionField];
                }

                return {
                    value: opt,
                    label,
                };
            }) ?? [];
        // if clearable, add null option
        if (objOptionClearable) {
            options = [{ value: null, label: objDefaultOptionString ?? PLEASE_SELECT___ }, ...options];
        }
        // get value from options
        // console.log('value', value)
        const optValue = options.find((opt) => (objOptionField ? _.isEqual(opt.value?.[objOptionField], value?.[objOptionField]) : _.isEqual(opt.value, value)));

        const customStyles = {
            control: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                minHeight: objectHeight + 'px',
            }),
            option: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                padding: '0 12px',
                lineHeight: objectHeight + 'px',
            }),
            singleValue: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                lineHeight: objectHeight + 'px',
            }),
            placeholder: (provided) => ({
                ...provided,
                lineHeight: objectHeight + 'px',
            }),
            clearIndicator: (provided) => ({
                ...provided,
                padding: '0 8px',
            }),
            valueContainer: (provided) => ({
                ...provided,
                width: 'fit-content',
                height: objectHeight + 'px',
                margin: '0',
                padding: '0px 8px',
                marginTop: '-5px',
            }),
            // down arrow
            dropdownIndicator: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                display: 'flex',
                alignItems: 'center',
            }),

            // Add styles for any other components you want to customize
        };

        component = (
            // <div>
            <Select
                value={optValue}
                placeholder={objPlaceholder}
                options={options}
                onChange={(newSelection) => {
                    if (newSelection.value === null) {
                        if (objOptionClearable) onChange(null);
                        return;
                    }
                    onChange(newSelection.value);
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: PLACEHOLDER_COLOR, // Placeholder color
                    },
                })}
                // height={(objectHeight - 18) + 'px'}
                styles={customStyles}
                isDisabled={readOnly}
                formatOptionLabel={(formatOptionLabelProps) => {
                    // this constrains the length of the option label
                    return (
                        <div title={formatOptionLabelProps.label} className="table-ellipses">
                            {formatOptionLabelProps.label}
                        </div>
                    );
                }}
            />
        );
        // </div>
    }

    // select component, uses react-select
    if (['select-multi'].includes(objType)) {
        // convert options to react-select format
        let options =
            objOptions?.map((opt) => {
                return {
                    value: opt,
                    // if optionField is specified, use it
                    label: props.object.getLabel?.(opt) ?? (objOptionField ? opt[objOptionField] : (opt?.label ?? opt)),
                };
            }) ?? [];

        // if clearable, add null option
        if (objOptionClearable) {
            options = [{ value: null, label: objDefaultOptionString ?? PLEASE_SELECT___ }, ...options];
        }

        const optValue = value;

        const originalObjectHeight = objectHeight;

        objectHeight *= Math.max(1, optValue?.length ?? 1);

        const customStyles = {
            control: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                minHeight: objectHeight + 'px',
            }),
            option: (provided) => ({
                ...provided,
                height: originalObjectHeight + 'px',
                padding: '0 12px',
                lineHeight: originalObjectHeight + 'px',
            }),
            singleValue: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                lineHeight: objectHeight + 'px',
            }),
            placeholder: (provided) => ({
                ...provided,
                lineHeight: objectHeight + 'px',
            }),
            clearIndicator: (provided) => ({
                ...provided,
                padding: '0 8px',
                // marginTop: '2px',
            }),
            valueContainer: (provided) => ({
                ...provided,
                width: 'fit-content',
                height: objectHeight + 'px',
                margin: '0',
                padding: '0px 8px',
                marginTop: '-5px',
            }),
            // down arrow
            dropdownIndicator: (provided) => ({
                ...provided,
                height: objectHeight + 'px',
                display: 'flex',
                alignItems: 'center',
            }),

            // Add styles for any other components you want to customize
        };

        component = (
            <Select
                isMulti={objType === 'select-multi'}
                value={optValue}
                placeholder={objPlaceholder}
                options={options}
                onChange={(newSelection) => {
                    onChange(newSelection);
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: PLACEHOLDER_COLOR, // Placeholder color
                    },
                })}
                styles={customStyles}
                isDisabled={readOnly}
            />
        );
    }

    const inputStyle = {
        cursor: objIsDisabled ? 'not-allowed' : undefined,
        height: `${objectHeight - 2}px`, // minus border
        padding: '0 10px',
        lineHeight: objectHeight - 10 - 2, // minus padding, minus border
    };

    /* number component */
    if (objType === 'number') {
        component = (
            <input
                id="name"
                type="text"
                placeholder={objPlaceholder}
                value={value}
                onChange={objIsDisabled ? () => {} : (e) => onChange(e.target.value)}
                disabled={objIsDisabled}
                style={inputStyle}
                readOnly={props.readOnly}
            />
        );
    }

    /* currency component */
    if (objType === 'currency') {
        component = (
            <input
                ref={currencyInput}
                id="name"
                placeholder={objPlaceholder}
                type="text"
                value={value}
                // value={noValue || isNaN(value)
                //     ? ''
                //     : value}
                onChange={(e) => {
                    if (!objIsDisabled) {
                        const currency = props.object.currency ?? CURRENCIES.USD;
                        const symbol = CURRENCY_SYMBOLS[currency];
                        onChange(e.target.value.replace(',', '').replace(symbol, ''));
                    }
                }}
                onFocus={() => (objIsDisabled ? currencyInput.current?.blur() : setCurrencyFocused(true))}
                onBlur={() => setCurrencyFocused(false)}
                style={inputStyle}
                readOnly={props.readOnly}
            />
        );
    }

    /* text component */
    if (objType === 'text') {
        component = (
            <input
                id="textInput"
                placeholder={objPlaceholder}
                type="text"
                value={value}
                onChange={objIsDisabled ? undefined : (e) => onChange(e.target.value)}
                disabled={objIsDisabled}
                style={inputStyle}
                readOnly={props.readOnly}
            />
        );
    }

    /* textarea component */
    if (objType === 'textarea') {
        objectHeight = null;
        component = (
            <textarea
                id="textInput"
                placeholder={objPlaceholder}
                value={value}
                rows={props.object.rows || 3}
                // cols={props.object.cols ?? 50}
                onChange={objIsDisabled ? undefined : (e) => onChange(e.target.value)}
                disabled={objIsDisabled}
                style={{
                    ...inputStyle,
                    height: 'unset',
                    minHeight: '25px',
                    maxHeight: '300px',
                    lineHeight: 'unset',
                    resize: 'vertical',
                    width: 'calc(100% - 10px)',
                    padding: '5px',
                    border: 'none',
                    boxShadow: 'var(--box-shadow)',
                    fontFamily: 'inherit',
                }}
                readOnly={props.readOnly}
            />
        );
    }

    if (objType === 'label') {
        component = (
            <div
                style={{
                    // fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    height: objectHeight + 'px',
                    fontWeight: 'bold',
                    ...objLabelStyle,
                }}
            >
                {props.object.text}
            </div>
        );
    }

    if (objType === 'header') {
        component = (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: objectHeight + 'px',
                    // fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: 'var(--color-primary)',
                    ...objLabelStyle,
                }}
            >
                {/* <h3>{props.object.text}</h3> */}
                {props.object.text}
            </div>
        );
    }

    if (objType === 'toggle') {
        component = (
            <div
                style={{
                    height: objectHeight + 'px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Multitoggle
                    containerStyle={{ width: '120px', height: '30px', ...props.object.style }}
                    options={props.object.options}
                    selection={value}
                    onSelect={(val) => onChange(val)}
                />
            </div>
        );
    }

    /* password component */
    if (objType === 'password') {
        component = (
            <div style={{ position: 'relative' }}>
                <input
                    id="passwordInput"
                    placeholder={objPlaceholder}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    name="passwordInput"
                    value={value}
                    onChange={objIsDisabled ? undefined : (e) => onChange(e.target.value)}
                    disabled={objIsDisabled}
                    style={inputStyle}
                    readOnly={props.readOnly}
                />
                {props.object.allowShow && (
                    <img
                        style={{
                            position: 'absolute',
                            right: '10px',
                            cursor: 'pointer',
                            top: '7px',
                        }}
                        src={`/images/icons/eye${showPassword ? '_filled' : ''}.png`}
                        alt={'show/hide password'}
                        title={'show/hide password'}
                        onClick={readOnly ? null : toggleShowPassword}
                    />
                )}
            </div>
        );
    }

    /* text component */
    if (objType === 'percent') {
        component = (
            <input
                id="name"
                placeholder={objPlaceholder}
                type="text"
                value={value}
                onChange={objIsDisabled ? undefined : (e) => onChange(e.target.value.replace('%', ''))}
                disabled={objIsDisabled}
                onFocus={() => (objIsDisabled ? percentInput.current?.blur() : setPercentFocused(true))}
                onBlur={() => setPercentFocused(false)}
                style={inputStyle}
                readOnly={props.readOnly}
            />
        );
    }

    /* checkbox component*/
    if (objType === 'checkbox') {
        component = (
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Checkbox id="name" setChecked={(value) => onChange(value)} checked={value} style={{ width: '20px', height: '20px' }} disabled={props.readOnly} />
                {!objHideLabel && <p style={{ fontSize: '16px', ...objLabelStyle }}>{objLabel}</p>}
            </div>
        );
    }

    /** custom component */
    if (objType === 'custom') {
        if (typeof objCustomComponent === 'function') {
            component = objCustomComponent(value, onChange, props.stateData, props.setStateData);
        } else {
            component = objCustomComponent;
        }
    }

    if (objType === 'rawText') {
        component = (
            <div
                style={{
                    // fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    height: objectHeight + 'px',
                }}
            >
                {value}
            </div>
        );
    }

    if (objType === 'dateDropdowns') {
        component = <DrawerDateInput date={value} setDate={onChange} readOnly={props.readOnly} />;
    }

    if (objType === 'date') {
        component = (
            <div
                style={{
                    // height: (objectHeight - 2) + 'px',
                    // lineHeight: (objectHeight - 2) + 'px',
                    border: 'none',
                    // width: '340px',
                    // width: 'fit-content',
                }}
                // className="drawerInputObject2-datePicker1"
            >
                <UTCDatePicker
                    containerStyle={{
                        width: '100%',
                    }}
                    placeholderText={objPlaceholder || 'MM/DD/YYYY'}
                    dateFormat="MM/dd/yyyy"
                    selected={(() => {
                        try {
                            return value ? TimelessDate.parse(value) : null;
                        } catch (error) {
                            console.error('Error parsing date', value);
                            return null;
                        }
                    })()}
                    onChange={(newDate) => onChange(newDate ? newDate.toString() : null)}
                    disabled={props.readOnly}
                />
            </div>
        );
    }

    if (objType === 'dateCalendar') {
        component = <ReactDatePicker selected={dateToUTCMidnight(value)} onChange={props.readOnly ? null : onChange} dateFormat="MM-dd-yyyy" />;
    }

    const renderLockIcon = (style) => (
        <FontAwesomeIcon
            title={props.object.lockedMessage || 'This field is locked'}
            icon={faLock}
            style={{
                color: 'var(--color-primary)',
                width: '12px',
                ...style,
            }}
        />
    );

    const renderLabel = () => {
        return (
            <label
                htmlFor="name"
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    ...(inlineLabel
                        ? {
                              fontWeight: 'bold',
                              marginRight: '10px',
                          }
                        : {
                              // justifyContent: 'space-between',
                          }),
                }}
            >
                <span style={{ width: '100%', ...objLabelStyle }}>
                    {objLabel}
                    {inlineLabel && ':'}
                </span>
                <span>
                    {props.notateRequired && objRequired && (
                        <div
                            style={{
                                fontSize: '1.2rem',
                                ...(inlineLabel
                                    ? {
                                          // right: '-10px',
                                      }
                                    : {
                                          // right: '0px',
                                      }),
                            }}
                        >
                            *
                        </div>
                    )}
                </span>
                {props.object.isLocked && renderLockIcon()}
            </label>
        );
    };

    if (props.object.shouldShow?.(props.stateData) === false) return null;

    const showLabel = objLabel && !objHideLabel && objType !== 'checkbox';

    return (
        <div
            key={'drawerInputObject2' + objType + objFieldName}
            style={{
                '--drawer-input-placeholder-color': PLACEHOLDER_COLOR,
                '--drawer-obj-height': objectHeight + 'px',
                ...props.object.containerStyle,
                ...(inlineLabel
                    ? {
                          // display: 'inline-flex',
                          // alignItems: 'center',
                          // justifyContent: 'space-between',
                          // width: '100%',
                      }
                    : {
                          // justifyContent: 'space-between',
                      }),
            }}
            onMouseEnter={() => props.object.onHover?.(true)}
            onMouseLeave={() => props.object.onHover?.(false)}
        >
            <div
                style={{
                    ...props.object.rowStyle,
                    ...(inlineLabel
                        ? {
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                          }
                        : {
                              // justifyContent: 'space-between',
                          }),
                }}
            >
                {showLabel && renderLabel()}
                {!showLabel && props.object.isLocked && (
                    <div>
                        {renderLockIcon({
                            marginTop: '10px',
                            marginBottom: '5px',
                            float: 'right',
                        })}
                        <div style={{ clear: 'both' }}></div>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    {props.object.renderLeftComponent?.()}
                    <div
                        style={{
                            // backgroundColor: 'green',
                            position: 'relative',
                            height: !objCustomComponent && objectHeight ? objectHeight + 'px' : null,
                            right: 0,
                            width: '100%',
                            ...props.object.componentStyle,
                        }}
                        onClick={() => props.object.onClick?.(props.value)}
                    >
                        {component}
                    </div>
                    {props.object.renderRightComponent?.()}
                </div>
            </div>

            <div>{props.errors && renderInputError(props.errors, objFieldName, false)}</div>
            <div style={{ height: spacerSize ?? 0 }}></div>
        </div>
    );
};
