import React from 'react';
import * as OTPAuth from 'otpauth';
import '../styles/totpDisplay.css';

class TOTPDisplay extends React.Component {
    state = {
        token: '',
    };

    componentDidMount() {
        this.setupTokenGeneration();
    }

    componentDidUpdate(prevProps) {
        // Check if the totp_key has changed, if so, reset the token generation process
        if (this.props.totp_key !== prevProps.totp_key) {
            this.setupTokenGeneration();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.initialDelay);
        clearInterval(this.interval);
    }

    setupTokenGeneration = () => {
        // Clear any existing timers
        clearTimeout(this.initialDelay);
        clearInterval(this.interval);

        this.generateToken(); // Generate token immediately
        const period = this.getPeriodFromKey(this.props.totp_key);
        // Adjust the interval to sync with the time intervals of the minute absolutely
        const currentTime = new Date();
        const secondsUntilNextInterval = (period - (currentTime.getSeconds() % period)) * 1000;
        this.initialDelay = setTimeout(() => this.generateToken(), secondsUntilNextInterval); // Initial delay to align with the period

        this.interval = setInterval(() => this.generateToken(), period * 1000); // Refresh based on the period
    };

    getPeriodFromKey = (totp_key) => {
        if (!totp_key) {
            return 30;
        }
        const periodMatch = totp_key.match(/&period=(\d+)/i);
        return periodMatch ? parseInt(periodMatch[1], 10) : 30; // Default TOTP period
    };

    getSecretFromKey = (totp_key) => {
        if (!totp_key) {
            return totp_key;
        }
        const secretMatch = totp_key.match(/^otpauth:\/\/totp\/.*secret=([^&]+)/i);
        return secretMatch ? secretMatch[1] : totp_key;
    };

    generateToken = () => {
        try {
            // check if a url key or a string key with regex
            const urlFormatRegex = /^otpauth:\/\/totp\/.*$/i;
            if (urlFormatRegex.test(this.props.totp_key)) {
                const totp = OTPAuth.URI.parse(this.props.totp_key);
                const token = totp.generate();
                this.setState({ token });
                return;
            }
            const formattedKey = this.getSecretFromKey(this.props.totp_key);
            const period = this.getPeriodFromKey(this.props.totp_key);
            const totp = new OTPAuth.TOTP({
                algorithm: 'SHA1',
                digits: 6,
                period: period,
                secret: formattedKey,
            });
            const token = totp.generate();
            this.setState({ token });
        } catch (error) {
            console.error('Failed to generate TOTP token:', error);
        }
    };

    render() {
        return (
            <div className="totp-display-container">
                <div className="totp-token">{this.state.token}</div>
            </div>
        );
    }
}

export default TOTPDisplay;
