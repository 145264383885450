// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/capitalCalledCircle.css';
import formatCurrency from '../../utilities/format/formatCurrency';

class CapitalCalledCircle extends Component {
    renderCircle = () => {
        const p = this.props;
        return (
            <>
                <div className="cccCircleContainer">
                    <div className="cccCircle" />
                    <div className="cccCircleFilled" />
                    <div className="cccCircleText" style={{ zIndex: 50000 }}>
                        <div>{formatCurrency(p.committedAmount, p.currency)}</div>
                        <div>Total Committed</div>
                    </div>
                </div>
            </>
        );
    };

    renderLabel = () => {
        const p = this.props;
        return (
            <div className="cccLabelContainer">
                <div className="cccLabelLine" />
                <div className="cccLabelText">
                    <div>{formatCurrency(p.calledAmount, p.currency)}</div>
                    <div>total called</div>
                </div>
            </div>
        );
    };

    render = () => {
        const p = this.props;
        return (
            <div
                className="widget cccComponent"
                style={{
                    '--cccCircle-size': p.size ? `${p.size}px` : '250px',
                    '--cccPercent': `${!p.calledAmount ? 0 : Math.max(0, Math.min((100 * p.calledAmount) / p.committedAmount, 100))}%`,
                }}
            >
                {this.renderCircle()}
                {this.renderLabel()}
            </div>
        );
    };
}

export default withRouter(CapitalCalledCircle);
