import React, { useState } from 'react';

import '../styles/draggableDivider.css';

const DEFAULT_BORDER_THICKNESS = 2;
const DEFAULT_THICKNESS = 2;
const MAX_DELTA = 250;

const DraggableDivider = (props) => {
    const [startPosition, setStartPosition] = useState(null);

    const handleDragStart = (e) => {
        setStartPosition(props.horizontal ? e.clientY : e.clientX);
    };

    const handleDragEnd = (e) => {
        setStartPosition(null);
    };

    const handleDrag = (e) => {
        if (!startPosition) return;
        const p = props;
        const currentPosition = p.horizontal ? e.clientY : e.clientX;
        const delta = currentPosition - startPosition;
        if (delta === 0 || Math.abs(delta) > MAX_DELTA) {
            return;
        }
        props.onDrag?.(delta);
        setStartPosition(currentPosition);
    };

    const getStyle = () => {
        const p = props;

        const totalWidth = p.size ?? DEFAULT_THICKNESS;
        let borderWidth = p.borderWidth ?? DEFAULT_BORDER_THICKNESS;
        if (borderWidth > totalWidth / 2) borderWidth = totalWidth / 2;
        const innerWidth = totalWidth - borderWidth * 2;

        const style = {
            width: p.horizontal ? '100%' : innerWidth,
            height: p.horizontal ? innerWidth : '100%',
            cursor: p.horizontal ? 'row-resize' : 'col-resize',
            border: `${borderWidth}px solid var(--color-light-gray)`,
        };

        return style;
    };

    return (
        <div className="draggableDivider_container" style={getStyle()} onDrag={handleDrag} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onMouseDown={handleDragStart} />
    );
};

export default DraggableDivider;
