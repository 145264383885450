import React from 'react';

import '../styles/statusCircle.css';

export const StatusCircle = (props) => {
    const { percent, animationTime, animationDoneCallback } = props;

    const [_percent, setPercent] = React.useState(0);
    React.useEffect(() => {
        let isMounted = true;

        if (animationTime) {
            Array.from(Array(100))
                .map((n, idx) => idx + 1)
                .forEach((n) =>
                    setTimeout(
                        () => {
                            setPercent(n);
                            if (n === 100) {
                                if (!isMounted) return;
                                const _ = animationDoneCallback?.();
                            }
                        },
                        (n / 100) * animationTime * 1000
                    )
                );
        } else if (percent) {
            setPercent(percent ?? 0);
        }

        return () => (isMounted = false);
    }, [percent, animationTime, animationDoneCallback]);

    const getStyle = () => {
        const style = {
            '--statusCircle-size': `${props.size ?? 60}px`,
            '--statusCircle-percent': _percent ?? 0,
            '--statusCircle-filledColor': props.filledColor ?? 'black',
            '--statusCircle-emptyColor': props.emptyColor ?? '#0000',
            '--statusCircle-thickness': props.thickness ?? 40,
            '--statusCircle-fontSize': props.size ? props.size / 5 : 15,
        };
        return { ...style, ...props.style };
    };

    return (
        <div className={'statusCircleContainer' + (props.onClick ? ' statusCircleContainerHover' : '')} style={getStyle()} onClick={props.onClick}>
            {props.showPercentText && <span>{_percent}%</span>}
        </div>
    );
};
