import { FileParseResult, FileTextResult } from '../../../../constants/file';
import BulkUploadHandler from './BulkUploadHandler';
import sharedFields, { isObjectId } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api from '../../../../api';
import UserUploadHandler, { UserUploadHandlerConfig, UserUploadHandlerCreateType } from './UserUploadHandler';

export interface InvestorUploadHandlerConfig extends Omit<UserUploadHandlerConfig, 'access'> {}

export interface InvestorUploadHandlerCreateType extends Omit<UserUploadHandlerCreateType, 'access'> {
    advisors?: string;
}

class InvestorUploadHandler extends BulkUploadHandler<InvestorUploadHandlerCreateType, InvestorUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'user' or 'valuation'
    type = BulkUploadType.investor;

    // instance of UserUploadHandler to use base functions
    userUploadHandlerInstance = new UserUploadHandler({
        access: 'investor',
    });

    // The base columns that are required for the bulk upload
    base_columns = {
        name: this.userUploadHandlerInstance.base_columns.name,
        email: this.userUploadHandlerInstance.base_columns.email,
        password: this.userUploadHandlerInstance.base_columns.password,
        advisors: {
            ...sharedFields.string,
            displayName: 'Advisors',
            fieldName: 'advisors',
            isValid: (val: string) => {
                const split = val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v);
                for (let i = 0; i < split.length; i++) {
                    if (!isObjectId(split[i])) {
                        return false;
                    }
                }
                return true;
            },
            format: (val: string) => {
                return val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v)
                    .join('/');
            },
            style: { width: '250px' },
            required: false,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof InvestorUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof InvestorUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof InvestorUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        delete sortedColumns.access;
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof InvestorUploadHandlerCreateType]: string }, columnOrder?: (keyof InvestorUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof InvestorUploadHandlerCreateType)[]): InvestorUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        return {
            name: parsedValues.name,
            email: parsedValues.email,
            password: parsedValues.password,

            // Only include the advisors if it's provided and valid.
            ...(parsedValues.advisors
                ? {
                      advisors: expectedColumns.advisors && expectedColumns.advisors.isValid(parsedValues.advisors) ? expectedColumns.advisors.format(parsedValues.advisors) : '',
                  }
                : {}),
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof InvestorUploadHandlerCreateType)[]
    ): Promise<FileParseResult<InvestorUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<InvestorUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<InvestorUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = ['The advisors column is a list of advisor IDs separated by slashes. For example, "5f7b1b7b4b3e4b001f4b3e4b/5f7b1b7b4b3e4b001f4b3e4b"'];
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof InvestorUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        let access = 'investor';

        let advisors = [];
        if (data.advisors) {
            advisors = data.advisors
                .split('/')
                .map((advisorId: string) => advisorId.trim())
                .filter((advisorId: string) => advisorId);
        }

        try {
            // Attempt to create a single user from the current object.
            const createRes = await api.post(
                '/users',
                {
                    name: data.name,
                    email: data.email,
                    password: data.password,
                    access,
                    advisors,
                },
                {},
                true
            );

            if (createRes.success && createRes.user?._id) {
                return {
                    success: true,
                    message: 'Investor created successfully',
                    id: createRes.user?._id,
                };
            } else {
                return {
                    success: false,
                    message: `Error creating Investor.`,
                };
            }
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating user:', err);
            return {
                success: false,
                message: `Error creating user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            const deleteRes = await api.delete(`/users/${id}`, {}, true);
            return {
                success: true,
                message: 'User deleted successfully',
            };
        } catch (err) {
            console.log('error deleting user:', err);
            return {
                success: false,
                message: `Error deleting user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the InvestorUploadHandler
export const getInvestorUploadHandler: (config: InvestorUploadHandlerConfig) => InvestorUploadHandler = (config: InvestorUploadHandlerConfig) => new InvestorUploadHandler(config);

export default InvestorUploadHandler;
